<template>
  <div>
    <v-app class="mx-auto">
      <v-app-bar app dense color="#fff" height="70px" style="z-index: 9">
        <v-app-bar-nav-icon large @click="drawer = true"></v-app-bar-nav-icon>

        <v-img
          max-width="150px"
          class="ml-5 mt-2"
          src="@/assets/logo/BKLTH.png"
        ></v-img>
        <v-spacer></v-spacer>
        <!-- <v-btn icon to="/cart" v-if="$store.state.Carts.length === 0">
          <v-icon class="text-h5">mdi-cart-outline</v-icon>
        </v-btn>
        <v-btn icon to="/cart" v-if="$store.state.Carts.length">
          <v-badge
            color="green"
            overlap
            :content="$store.state.Carts.length"
            v-if="$store.state.Carts.length"
          >
            <v-icon class="text-h5">mdi-cart-outline</v-icon>
          </v-badge>
        </v-btn> -->
        <v-btn icon to="/member" v-if="!isLogedin">
          <v-icon class="text-h5 mr-2">mdi-lock-outline</v-icon>
        </v-btn>
        <v-btn icon v-if="isLogedin" @click="logout">
          <v-icon class="text-h5 mr-2">mdi-logout-variant</v-icon>
        </v-btn>
      </v-app-bar>

      <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
        style="
          position: fixed;
          top: 0;
          left: 0;
          overflow-y: scroll;
          z-index: 10;
        "
      >
        <v-img
          max-width="300px"
          max-height="300px"
          class="mx-auto my-5"
          src="@/assets/logo/BKLTH.png"
        ></v-img>
        <v-list nav dense>
          <v-list-item-group
            v-model="group"
            active-class="text--accent-5"
            class="text-center"
          >
            <v-divider></v-divider>
            <v-list-item
              class="text-center"
              @click="
                ChangePage({
                  key: 'menuIndex',
                  text: 'index',
                  path: '/',
                  name: 'index',
                  icon: 'mdi-home',
                })
              "
            >
              <p style="margin: 0px">
                <b
                  style="font-size: 0.8rem"
                  @click="
                    ChangePage({
                      key: 'menuIndex',
                      text: 'index',
                      path: '/',
                      name: 'index',
                      icon: 'mdi-home',
                    })
                  "
                  >หน้าหลัก</b
                >
              </p>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item class="text-center">
              <p style="margin: 0px">
                <b style="font-size: 0.8rem">เลือกซื้อลอตเตอรี่</b>
              </p>
            </v-list-item>
            <v-list-item
              style="margin-top: 0px"
              @click="
                ChangePage({
                  key: 'menulotterythai',
                  text: 'lotterythai',
                  path: '/lotterythai',
                  name: 'lotterythai',
                  icon: 'mdi-logout-variant',
                })
              "
            >
              <v-img
                max-width="150px"
                class="mx-auto my-0"
                src="@/assets/logo/THLLogo.png"
                @click="
                  ChangePage({
                    key: 'menulotterythai',
                    text: 'lotterythai',
                    path: '/lotterythai',
                    name: 'lotterythai',
                    icon: 'mdi-logout-variant',
                  })
                "
              ></v-img>
            </v-list-item>
            <!-- <v-divider></v-divider>
            <v-list-item
              @click="
                ChangePage({
                  key: 'menuPowerBall',
                  text: 'Power Ball',
                  path: '/powerBall',
                  name: 'powerBall',
                  icon: 'mdi-logout-variant',
                })
              "
            >
              <v-img
                max-width="150px"
                class="mx-auto my-1"
                src="@/assets/logo/Logo_USA.png"
                @click="
                  ChangePage({
                    key: 'menuPowerBall',
                    text: 'Power Ball',
                    path: '/powerBall',
                    name: 'powerBall',
                    icon: 'mdi-logout-variant',
                  })
                "
              ></v-img>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item
              @click="
                ChangePage({
                  key: 'megamillions',
                  text: 'Mega Millions',
                  path: '/megamillions',
                  name: 'megamillions',
                  icon: 'mdi-logout-variant',
                })
              "
            >
              <v-img
                max-width="150px"
                class="mx-auto my-5"
                src="@/assets/logo/MegaMillionslogo.png"
                @click="
                  ChangePage({
                    key: 'megamillions',
                    text: 'Mega Millions',
                    path: '/megamillions',
                    name: 'megamillions',
                    icon: 'mdi-logout-variant',
                  })
                "
              ></v-img>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item
              @click="
                ChangePage({
                  key: 'eurojackpot',
                  text: 'Euro Jackpot',
                  path: '/eurojackpot',
                  name: 'eurojackpot',
                  icon: 'mdi-logout-variant',
                })
              "
            >
              <v-img
                max-width="80px"
                class="mx-auto my-5"
                src="@/assets/logo/eurojackpot.png"
                @click="
                  ChangePage({
                    key: 'menueurojackpot',
                    text: 'Euro Jackpot',
                    path: '/eurojackpot',
                    name: 'eurojackpot',
                    icon: 'mdi-logout-variant',
                  })
                "
              ></v-img>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item
              @click="
                ChangePage({
                  key: 'menupowerBallAus',
                  text: 'Power Ball Blue',
                  path: '/powerBallAus',
                  name: 'powerBallAus',
                  icon: 'mdi-logout-variant',
                })
              "
            >
              <v-img
                max-width="130px"
                class="mx-auto my-5"
                src="@/assets/logo/Logo_AUS.png"
                @click="
                  ChangePage({
                    key: 'menupowerBallAus',
                    text: 'Power BallBlue',
                    path: '/powerBallAus',
                    name: 'powerBallAus',
                    icon: 'mdi-logout-variant',
                  })
                "
              ></v-img>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item
              @click="
                ChangePage({
                  key: 'minilotto',
                  text: 'Mini Lotto',
                  path: '/minilotto',
                  name: 'minilotto',
                  icon: 'mdi-logout-variant',
                })
              "
            >
              <v-img
                max-width="150px"
                class="mx-auto my-5"
                src="@/assets/logo/Logo_JAP.png"
                @click="
                  ChangePage({
                    key: 'minilotto',
                    text: 'Mini Lotto',
                    path: '/minilotto',
                    name: 'minilotto',
                    icon: 'mdi-logout-variant',
                  })
                "
              ></v-img>
            </v-list-item> -->

            <!-- <v-list-item style="margin-top: 0px">
              <v-img
                style="margin-top: -30px"
                max-width="150px"
                class="mx-auto my-5"
                src="@/assets/logo/blue_side.png"
                @click="
                  ChangePage({
                    key: 'menubluedragon',
                    text: 'bluedragon',
                    name: 'bluedragon', 
                    icon: 'mdi-logout-variant',
                  })
                "
              ></v-img>
            </v-list-item> -->
            <!-- <v-divider></v-divider>
            <v-list-item
              style="margin-top: 0px"
              @click="
                ChangePage({
                  key: 'menulaolottery',
                  text: 'laolottery',
                  path: '/lotterylao',
                  name: 'laolottery',
                  icon: 'mdi-logout-variant',
                })
              "
            >
              <v-img
                max-width="150px"
                class="mx-auto my-5"
                src="@/assets/logo/lao_side.png"
                @click="
                  ChangePage({
                    key: 'menulaolottery',
                    text: 'laolottery',
                    path: '/lotterylao',
                    name: 'laolottery',
                    icon: 'mdi-logout-variant',
                  })
                "
              ></v-img>
            </v-list-item>
            <v-divider></v-divider> -->

            <v-list-item class="text-center">
              <p style="margin: 0px">
                <b style="font-size: 0.8rem">การซื้อลอตเตอรี่</b>
              </p>
            </v-list-item>
            <v-list-item class="text-center pl-6" @click="gotomember()">
              <p
                v-if="!isLogedin"
                style="margin: 0px; font-size: 0.8rem"
                @click="gotomember()"
              >
                สมัคร/เข้าสู่ระบบ
              </p>
              <p
                v-if="isLogedin"
                style="margin: 0px; font-size: 0.8rem"
                @click="gotomember()"
              >
                ข้อมูลส่วนตัว
              </p>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item
              class="text-center pl-6"
              @click="
                ChangePage({
                  key: 'menusafe',
                  text: 'safe',
                  path: '/safe',
                  name: 'safe',
                  icon: 'mdi-logout-variant',
                })
              "
            >
              <p style="margin: 0px; font-size: 0.8rem">ประวัติสั่งซื้อ</p>
            </v-list-item>
            <v-divider></v-divider>
            <!-- <v-list-item
              class="text-center pl-6"
              @click="
                ChangePage({
                  key: 'menufaq',
                  text: 'faq-net',
                  path: '/faq-net',
                  name: 'faq-net',
                  icon: 'mdi-logout-variant',
                })
              "
            >
              <p style="margin: 0px; font-size: 0.8rem">คำถามที่พบบ่อย</p>
            </v-list-item>
            <v-divider></v-divider> -->
            <v-list-item
              class="text-center pl-6"
              @click="
                ChangePage({
                  key: 'menucondition',
                  text: 'conditions-net',
                  path: '/conditions-net',
                  name: 'conditions-net',
                  icon: 'mdi-logout-variant',
                })
              "
            >
              <p style="margin: 0px; font-size: 0.8rem">เงื่อนไขการให้บริการ</p>
            </v-list-item>
            <v-divider></v-divider>
            <!-- <v-list-item
              class="text-center pl-6"
              @click="
                ChangePage({
                  key: 'menuresult',
                  text: 'result-blog',
                  path: '/result-blog',
                  name: 'result-blog',
                  icon: 'mdi-logout-variant',
                })
              "
            >
              <p style="margin: 0px; font-size: 0.8rem">ผลรางวัลทุกงวด</p>
            </v-list-item>
            <v-divider></v-divider> -->

            <!-- <v-list-item class="text-center" @click="gotoprize()">
              <p style="margin: 0px; font-size: 0.8rem">การขึ้นเงินรางวัล</p>
            </v-list-item>
            <v-divider></v-divider> -->
            <!-- <v-list-item class="text-center">
              <p
                style="margin: 0px; font-size: 0.8rem"
                @click="gotopurchaseconditions()"
              >
                เงื่อนไขและข้อตกลง
              </p>
            </v-list-item>
            <v-divider></v-divider> -->
            <!-- <v-list-item class="text-center" @click="gotoabout()">
              <p style="margin: 0px; font-size: 0.8rem">เกี่ยวกับเรา</p>
            </v-list-item>
            <v-divider></v-divider> -->
            <v-list-item
              class="text-center pl-6"
              @click="
                ChangePage({
                  key: 'menudealer',
                  text: 'dealers-net',
                  path: '/dealers-net',
                  name: 'dealers-net',
                  icon: 'mdi-logout-variant',
                })
              "
            >
              <p
                style="margin: 0px; font-size: 0.8rem"
                @click="
                  ChangePage({
                    key: 'menudealer',
                    text: 'dealers-net',
                    path: '/dealers-net',
                    name: 'dealers-net',
                    icon: 'mdi-logout-variant',
                  })
                "
              >
                <!-- www.ddlottery.net/dealers-net -->
                ตัวแทนจำหน่าย
              </p>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item
              class="text-center pl-6"
              @click="
                ChangePage({
                  key: 'menucontact',
                  text: 'contact-net',
                  path: '/contact-net',
                  name: 'contact-net',
                  icon: 'mdi-logout-variant',
                })
              "
            >
              <p style="margin: 0px; font-size: 0.8rem">ติดต่อเรา</p>
            </v-list-item>
            <!-- <v-list-item
              v-for="item in Navigator"
              :key="item.key"
              @click="ChangePage(item)"
            >
              <v-badge
                color="green"
                :content="$store.state.Carts.length"
                inline
                v-if="$store.state.Carts.length && item.name == 'cart'"
              >
              </v-badge>
              <span style="font-size: 13px">{{ item.text }}</span>
            </v-list-item> -->
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>

      <v-main class="pa-0">
        <!-- <router-view /> -->
        <v-card outlined class="body-card pa-4" v-if="agentData">
          <v-row justify="center" align="center">
            <v-col
              cols="12"
              md="2"
              style="text-align: -webkit-center"
              v-if="agentData.bannerImgUrl"
            >
              <v-img
                max-width="auto"
                class="ml-5 mt-0"
                :src="agentData.bannerImgUrl"
                @click="gotolink(agentData.bannerLink)"
              ></v-img>
            </v-col>
          </v-row>
          <v-row justify="center" align="center">
            <!-- <v-col cols="12" md="2" style="text-align: -webkit-center">
              <v-img
                max-width="110px"
                class="ml-5 mt-0"
                :src="agentData.profileUrl"
              ></v-img>
            </v-col> -->
            <v-col cols="12" md="4">
              <span style="font-size: 22px"
                ><b>รหัสตัวแทน:</b> {{ agentData.agentNo }}</span
              >
              <br />
              <span style="font-size: 22px"
                ><b>ชื่อตัวแทน:</b> {{ agentData.firstName }}
                {{ agentData.lastName }}</span
              >
              <br />
              <span style="font-size: 22px"
                ><b>หมายเลขโทรศัพท์ตัวแทน:</b> {{ agentData.tel }}</span
              >
            </v-col>

            <!-- <v-col cols="12" md="4">
              <span style="font-size: 22px"
                ><b>อีเมลตัวแทน:</b> {{ agentData.email }}</span
              >
              <br />
              <span style="font-size: 22px"
                ><b>หมายเลขโทรศัพท์ตัวแทน:</b> {{ agentData.tel }}</span
              >
            </v-col> -->
          </v-row>
        </v-card>
        <!-- แบ่งหน้า  -->
        <!-- <v-carousel
          cycle
          height="400px"
          hide-delimiter-background
          show-arrows-on-hover
          class="mb-4 mx-auto"
        >
          <v-carousel-item v-for="(item, i) in items" :key="i">
            <v-img
              :src="item.src"
              width="90%"
              height="auto"
              class="mx-auto mr-4"
            />
          </v-carousel-item>
        </v-carousel>

        <v-row class="my-8 text-center">
          <v-col cols="12" md="12" style="padding: 20px">
            <v-img
              class="ml-5 mt-2"
              src="@/assets/logo/powerball_banner.png"
              @click="clickPoweball()"
            ></v-img>
            <v-img
              class="ml-5 mt-2"
              src="@/assets/logo/lotto_banner.png"
              @click="clickLotto()"
            ></v-img>
            <v-img
              class="ml-5 mt-2"
              src="@/assets/logo/lao_banner.png"
              @click="clickLao()"
            ></v-img>
          </v-col>
        </v-row>

        <v-row
          class="mb-6 text-center px-1 ml-1 black--text"
          style="
            background-image: linear-gradient(
              to left,
              #e8be50 0%,
              #f6e094 35%,
              #f9f295 50.39%,
              #e5c24c 100%
            );
          "
        >
         
          <v-col cols="12">
            <span>เลขท้าย 2 ตัวที่มีคนซื้อมากที่สุด 10 อันดับ</span>
          </v-col>
          <v-col cols="12">
            <span class="mr-2">55</span>
            <span class="mr-2">55</span>
            <span class="mr-2">55</span>
            <span class="mr-2">55</span>
            <span class="mr-2">55</span>
            <span class="mr-2">55</span>
            <span class="mr-2">55</span>
            <span class="mr-2">55</span>
            <span class="mr-2">55</span>
            <span class="mr-2">55</span>
          </v-col>

          <v-col cols="12">
            <v-card class="text-center mx-4">
              <v-row class="my-4">
                <v-col cols="12" class="py-0">
                  <span>ผลสลากกินแบ่งรัฐบาล</span>
                </v-col>
                <v-col cols="12" class="py-0 mb-4">
                  <span>งวดประจำวันที่ 01 พฤศจิกายน 2564</span>
                </v-col>
                <v-col cols="12" class="py-0">
                  <hr style="border-top: 1px solid #d5ab61" />
                </v-col>
                <v-col cols="6" class="py-0 my-2">
                  <span>รางวัลที่ 1</span>
                  <h2 class="body-card mx-2 px-4">500005</h2>
                  <small>รางวัลละ 6,000,000 บาท</small>
                </v-col>
                <v-col cols="6" class="py-0 my-2">
                  <span>รางวัลเลขท้าย 2 ตัว</span>
                  <h2 class="mb-0">99</h2>
                  <small>รางวัลละ 2,000 บาท</small>
                </v-col>
                <v-col cols="12" class="py-0">
                  <hr style="border-top: 1px solid #d5ab61" />
                </v-col>
                <v-col cols="6" class="py-0 my-2">
                  <span>รางวัลเลขหน้า 3 ตัว</span>
                  <h1 class="mb-0">
                    <span class="mx-2">999</span>
                    <span class="mx-2">999</span>
                  </h1>
                  <small>2 รางวัลๆละ 4,000 บาท</small>
                </v-col>
                <v-col cols="6" class="py-0 my-2">
                  <span>รางวัลเลขท้าย 3 ตัว</span>
                  <h1 class="mb-0">
                    <span class="mx-2">222</span>
                    <span class="mx-2">222</span>
                  </h1>
                  <small>2 รางวัลๆละ 4,000 บาท</small>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row> -->
        <v-app>
          <iframe
            src="https://www.ddlottery.com/main-net"
            width="100%"
            height="90%"
          ></iframe>
        </v-app>

        <!-- แบ่งหน้า -->
      </v-main>

      <div>
        <!-- <v-row>
          <v-col cols="12" md="12" sm="12">
            <v-btn
              small
              color="#00B900"
              dark
              absolute
              bottom
              right
              fixed
              elevation="2"
              rounded
              style="margin-bottom: 90px; z-index: 2; padding-top: -20px"
              @click="printpo"
              class="btnPrint"
            >
              <v-img
                src="@/assets/line-logo.png"
                width="20px"
                class="ml-0"
              ></v-img>
            </v-btn>
          </v-col>
        </v-row> -->
      </div>
    </v-app>

    <!-- <back-to-top visibleoffset="400" bottom="150px" right="20px"
      ><button type="button" class="btn btn-info btn-to-top">
        <i class="fa fa-chevron-up"></i></button
    ></back-to-top> -->

    <v-bottom-navigation
      fixed
      :value="value"
      color="#9b6810"
      height="80"
      style="font-size: 20px"
    >
      <v-btn value="home" class="btm-btn" to="/index" :ripple="false">
        <span>หน้าหลัก</span>
        <v-icon>mdi-home-outline</v-icon>
      </v-btn>

      <v-btn
        value="cart"
        class="btm-btn"
        to="/cart"
        v-if="$store.state.Carts.length"
      >
        <span>{{ time }}</span>
        <span>ตะกร้า</span>
        <v-badge
          color="green"
          :content="$store.state.Carts.length"
          v-if="$store.state.Carts.length"
        >
          <v-icon>mdi-cart-outline</v-icon>
        </v-badge>
      </v-btn>
      <v-btn
        value="cart"
        class="btm-btn"
        to="/cart"
        v-if="$store.state.Carts.length === 0"
      >
        <span>ตะกร้า</span>
        <v-icon>mdi-cart-outline</v-icon>
      </v-btn>
      <v-btn value="safe" class="btm-btn" to="/safe" v-if="isLogedin">
        <span>ลอตเตอรี่ของฉัน</span>
        <v-icon>mdi-card-text-outline</v-icon>
      </v-btn>
      <v-btn value="safe" class="btm-btn" to="/member" v-if="!isLogedin">
        <span>เข้าสู่ระบบ</span>
        <v-icon>mdi-lock-outline</v-icon>
      </v-btn>

      <v-btn value="contact" class="btm-btn" to="/contact">
        <span>เกี่ยวกับเรา</span>
        <v-icon>mdi-city-variant-outline</v-icon>
      </v-btn>
    </v-bottom-navigation>
    <div class="text-center">
      <v-dialog v-model="dialog" width="96vw">
        <v-card>
          <v-card-title class="white--text" style="background-color: #97b189">
            <span style="">ข้อตกลงและเงื่อนไข</span>
            <span style="">ในการซื้อลอตเตอรี่ออนไลน์</span>
            <span style="">ผ่านมังกรฟ้า</span>
            <br />
            <span style=""> โดย บริษัท มังกร ดิจิตอล จำกัด </span>
            <span style="">(BD00029D) ตัวแทนจำหน่ายยี่ปั๊วรายใหญ่</span>
          </v-card-title>

          <v-card-text>
            <span
              >1. มังกรฟ้าและ Bluedragonคือ
              แพลตฟอร์มช่องทางการจำหน่ายสลากกินแบ่งรัฐบาล หรือ ลอตเตอรี่
              ในราคาใบละ 80 บาท
              โดยผู้จำหน่ายสลากจะเรียกเก็บค่าบริการในการค้นหาตัวเลขตามที่ลูกค้าต้องการ</span
            ><br />
            <span
              >2. บริษัท มังกร ดิจิตอล จำกัด คือ
              ตัวแทนผู้จัดจำหน่ายยี่ปั๊วรายใหญ่ รหัส BD00029D ของ มังกรฟ้า
              ลอตเตอรี่ออนไลน์</span
            ><br />
            <span
              >3. ลูกค้าผู้ซื้อสลากกินแบ่งรัฐบาล หรือ ลอตเตอรี่
              ผ่านทางมังกรฟ้าจะต้องเป็นผู้มีอายุ 20 ปีขึ้นไป
              และไม่ได้ทำการสั่งซื้อขณะอยู่ในสถานศึกษา</span
            ><br />
            <span>
              4. การซื้อสลากกินแบ่งรัฐบาล หรือ
              ลอตเตอรี่ในรูปแบบออนไลน์ผ่านทางมังกรฟ้า
              เป็นการซื้อในรูปแบบการซื้อฝากร้านเท่านั้น
              หมายถึงเมื่อลูกค้าซื้อสลากกินแบ่งรัฐบาล หรือ
              ลอตเตอรี่กับผู้จำหน่ายสลากผ่านทางมังกรฟ้าแล้ว
              ลูกค้ายินยอมและสมัครใจที่จะฝากต้นฉบับสลากกินแบ่งรัฐบาล หรือ
              ลอตเตอรี่
              ไว้กับมังกรฟ้าให้เป็นผู้ดูแลรักษาและจัดเก็บอย่างเป็นระบบไว้แทน
              และสามารถติดต่อขอรับต้นฉบับได้หากถูกรางวัล</span
            ><br />
            <span
              >5. การอัพโหลด (Upload) หลักฐานการชำระเงิน หรือ สลิปโอนเงิน
              หากลูกค้าแนบสลิปผิดพลาด
              หรือเกิดข้อผิดพลาดประการใดเกี่ยวกับการอัปโหลดหลักฐานการชำระเงิน
              ให้ลูกค้ารีบติดต่อผู้จำหน่ายสลากทันทีเพื่อดำเนินการแก้ไขปัญหาที่เกิดขึ้น
              หรือแนบสลิปใหม่ หากกรณีไม่ได้รับการยืนยันคำสั่งซื้อ
              มังกรฟ้าขอสงวนสิทธิในการไม่รับผิดชอบในข้อผิดพลาดดังกล่าวใด ๆ
              ทั้งสิ้น </span
            ><br />
            <span
              >6.
              หากลูกค้าอัพโหลดหลักฐานการชำระเงินที่ไม่ตรงกับข้อมูลการสั่งซื้อ
              (สลิปที่ไม่ถูกต้อง)
              ด้วยมีเจตนากระทำการอย่างหนึ่งอย่างใดอันเป็นการเชื่อได้ว่าเป็นการกระทำโดยเจตนาทุจริต
              หากมังกรฟ้าสืบทราบ และ/หรือ พบเห็นการกระทำดังกล่าว
              มังกรฟ้าสงวนสิทธิและถือว่าการสั่งซื้อนั้นเป็นโมฆะ
              มังกรฟ้าจะดำเนินการยกเลิกคำสั่งซื้อและไม่มีการจัดส่งลอตเตอรี่ที่ถูกรางวัลให้แก่บุคคลนั้น
              พร้อมทั้งนำไอดี (ID) ผู้ใช้งานเข้าในระบบแบล็คลิสต์ (Blacklist)
              เพื่อไม่ให้ลูกค้าดำเนินการสั่งจองหรือสั่งซื้อใด ๆ
              ผ่านทางมังกรฟ้าได้อีกต่อไป
              และมังกรฟ้าขอสงวนสิทธิ์ในการดำเนินการแจ้งความร้องทุกข์ต่อพนักงานสอบสวนเพื่อดำเนินคดีต่อไป</span
            ><br />
            <span
              >7.
              รายการสั่งซื้อของลูกค้าจะดำเนินการสำเร็จต่อเมื่อผู้จำหน่ายสลากทำการตรวจสอบและอนุมัติรายการสั่งซื้อของลูกค้าแล้วเท่านั้น
              หากรายการสั่งซื้อสำเร็จ ลูกค้าจะได้รับข้อความ (SMS)
              แจ้งสถานะคำสั่งซื้อสำเร็จส่งไปยังหมายเลขโทรศัพท์มือถือที่ลูกค้าใช้ลงทะเบียนไว้จึงเป็นการเสร็จสิ้น
              คำสั่งซื้อสำเร็จ
              หากลูกค้าไม่ได้รับข้อความแจ้งคำสั่งซื้อไปยังหมายเลขโทรศัพท์ที่ลูกค้าใช้ลงทะเบียนไว้
              ขอให้ลูกค้ากรุณาติดต่อผู้จำหน่ายสลากที่ลูกค้าได้ติดต่อเพื่อซื้อลอตเตอรี่</span
            ><br />
            <span
              >8. หลังจากคำสั่งซื้อของลูกค้าสำเร็จแล้วนั้น
              ลูกค้าจะไม่สามารถทำการแก้ไข หรือ ทำการยกเลิกคำสั่งซื้อนั้นได้ 9.
              โอนเงินและอัพโหลดสลิปโอนเงินบนเวบไซต์ภายใน 1 ชม. เท่านั้น
              เพื่อป้องกันออเดอร์หมดเวลา แฃะถูกยกเลิกอัตโนมัติ 10.
              หากอัพโหลดสลิปด้วยตนเองไม่ได้ ส่งให้เราทางไลน์ช่วยอัพโหลดแทนได้
              (ทั้งนี้ แอดมินเป็นเพียงผู้ช่วยเหลืออัพโหลดสลิปโอนแทนเท่านั้น
              ดังนั้นกรุณาส่งสลิปแจ้งโอนก่อนหมดเวลามากกว่า 30 นาที)</span
            ><br />
            <span>
              11. หากส่งสลิปโอนแล้วหรืออัพโหลดสลิปโอนแล้ว แต่ยังไม่ได้รับ SMS
              ยืนยันออเดอร์สมบูรณ์ ใน 30 นาที
              ลูกค้าต้องแจ้งตัวแทนทันทีทางไลน์เท่านั้น</span
            ><br />
            <span
              >12. หากออเดอร์หมดเวลาทุกกรณี/ยกเลิกทุกกรณี
              ลูกค้าสามารถสั่งซื้อใหม่อีกครั้งได้ด้วยการแนบสลิปโอนเดิมที่โอนแล้ว
              หรือกรณีลอตเตอรี่หมดแล้วกรุณาติดต่อรับเงินโอนคืนเต็มจำนวนเท่านั้น</span
            ><br />
            <span
              >13. เลขสลากฯ
              ที่ถูกรางวัลของออเดอร์ที่สถานะหมดเวลา/ถูกยกเลิกในทุกกรณี
              ไม่สามารถขึ้นเงินรางวัลได้
              กรุณาติดต่อรับเงินโอนคืนค่าลอตเตอรี่เต็มจำนวนเท่านั้น
              ยอมรับข้อตกลงและเงื่อนไข
              ข้อตกลงและเงื่อนไขการสั่งซื้อลอตเตอรี่มังกรฟ้า ลอตเตอรี่ออนไลน์
              โดยบริษัท มังกร ดิจิตอล จำกัด
              เมื่อลูกค้าได้เลือกเลขสลากใส่ตะกร้าสินค้า
              และทำตามขั้นตอนบนเวบไซต์แล้ว ในการกดสั่งซื้อและกดยืนยัน ทางบริษัทฯ
              จะถือว่าลูกค้าได้ตกลงและยอมรับเงื่อนไขของ บจก. มังกร ดิจิตอล และ
              บจก. มังกรฟ้า ลอตเตอรี่ออนไลน์
              ที่แจ้งเตือนให้อ่านและยอมรับก่อนการกด "ยืนยัน" เรียบร้อยแล้ว •
              โอนเงินและอัพโหลดสลิปโอนเงินบนเวบไซต์ภายใน 1 ชม. เท่านั้น
              เพื่อป้องกันออเดอร์หมดเวลา •
              ลูกค้าต้องตรวจสอบสถานะออเดอร์ของตนเองหลังการสั่งซื้อทุกครั้งที่เมนู
              "ประวัติการสั่งซื้อ" • หากอัพโหลดสลิปด้วยตนเองไม่ได้
              ส่งให้เราทางไลน์ช่วยอัพโหลดแทนได้ (ทั้งนี้
              แอดมินเป็นเพียงผู้ช่วยเหลืออัพโหลดสลิปแทนเท่านั้น
              ดังนั้นกรุณาส่งสลิปแจ้งโอนก่อนหมดเวลามากกว่า 30 นาที) •
              หากส่งสลิปโอนแล้วหรืออัพโหลดสลิปโอนแล้ว แต่ยังไม่ได้รับ SMS
              ยืนยันออเดอร์สมบูรณ์ ใน 30 นาที
              ลูกค้าต้องแจ้งตัวแทนทันทีทางไลน์เท่านั้น •
              หากออเดอร์หมดเวลาทุกกรณี
              ลูกค้าสามารถสั่งซื้อใหม่อีกครั้งได้ด้วยการแนบสลิปเดิมที่โอนแล้ว
              หรือกรณีลอตเตอรี่หมดแล้วกรุณาติดต่อรับเงินโอนคืนเต็มจำนวน •
              เลขสลากฯ ที่ถูกรางวัลของออเดอร์ที่สถานะหมดเวลา/ถูกยกเลิกในทุกกรณี
              ไม่สามารถขึ้นเงินรางวัลได้</span
            >
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialog = false"> ถอยกลับ </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { decode } from "@/services/decode";
import { encode } from "@/services/encode";
import moment from "moment";
export default {
  data() {
    return {
      agentCode: "",
      agentData: [],
      time: "",
      min: 15,
      sec: 0,
      drawer: null,
      toggle: null,
      dialog: false,
      show: false,
      checkPrivacy: false,
      carts: [],
      content: 0,
      value: 1,
      group: null,
      isLogedin: false,
      menuRight: "อื่นๆ",
      today: new Date(),
      Navigator: [
        {
          key: "menu1",
          text: "หน้าหลัก",
          path: "/index",
          name: "home",
          icon: "mdi-home-outline",
        },
        // {
        //   key: "menuPowerBall",
        //   text: "Power Ball",
        //   path: "/powerBall",
        //   name: "powerBall",
        //   icon: "mdi-logout-variant",
        // },
        // {
        //   key: "menu7",
        //   text: "ซื้อลอตเตอรี่",
        //   path: "/",
        //   name: "home",
        //   icon: "mdi-magnify",
        // },
        {
          key: "menu2",
          text: "เข้าสู่ระบบ/สมัครสมาชิก",
          path: "/member",
          name: "member",
          icon: "mdi-account-outline",
        },
        // {
        //   key: "menu3",
        //   text: "เข้าสู่ระบบ",
        //   path: "/member",
        //   name: "member",
        //   icon: "mdi-lock-outline",
        // },
        // {
        //   key: "menu4",
        //   text: "ลอตเตอรี่ของฉัน",
        //   path: "/safe",
        //   name: "safe",
        //   icon: "mdi-card-text-outline",
        // },
        {
          key: "menu5",
          text: "ตะกร้า",
          path: "/cart",
          name: "cart",
          icon: "mdi-cart-outline",
        },
        {
          key: "menu99",
          text: "เงื่อนไขและข้อตกลง",
          // path: "/winprize",
          name: "privacy",
          icon: "mdi-text-box-check-outline",
        },
        {
          key: "menu6",
          text: "เกี่ยวกับเรา",
          path: "/contact",
          name: "contact",
          icon: "mdi-city-variant-outline",
        },
        {
          key: "menu6",
          text: "ติดต่อเรา",
          path: "/contact",
          name: "contact",
          icon: "mdi-city-variant-outline",
        },
        {
          key: "menu6",
          text: "ตัวแทนจำหน่าย",
          path: "/contact",
          name: "contact",
          icon: "mdi-city-variant-outline",
        },
      ],
      NavigatorLogin: [
        {
          key: "menu1",
          text: "หน้าหลัก",
          path: "/",
          name: "home",
          icon: "mdi-home-outline",
        },
        {
          key: "menuPowerBall",
          text: "Power Ball",
          path: "/powerBall",
          name: "powerBall",
          icon: "mdi-logout-variant",
        },
        // {
        //   key: "menu7",
        //   text: "ซื้อลอตเตอรี่",
        //   path: "/",
        //   name: "home",
        //   icon: "mdi-magnify",
        // },
        {
          key: "menu5",
          text: "ตะกร้า",
          path: "/cart",
          name: "cart",
          icon: "mdi-cart-outline",
        },
        // {
        //   key: "menu3",
        //   text: "ลอตเตอรี่ของฉัน",
        //   path: "/safe",
        //   name: "safe",
        //   icon: "mdi-card-text-outline",
        // },
        // {
        //   key: "menu4",
        //   text: "แจ้งถูกล้อตเตอรี่ขึ้นรางวัล",
        //   // path: "/winprize",
        //   name: "winprize",
        //   icon: "mdi-cash",
        // },
        {
          key: "menu99",
          text: "เงื่อนไขและข้อตกลง",
          // path: "/winprize",
          name: "privacy",
          icon: "mdi-text-box-check-outline",
        },
        {
          key: "menu2",
          text: "ข้อมูลส่วนตัว",
          path: "/member",
          name: "member",
          icon: "mdi-account",
        },
        {
          key: "menu6",
          text: "เกี่ยวกับเรา",
          path: "/contact",
          name: "contact",
          icon: "mdi-city-variant-outline",
        },
        {
          key: "menu8",
          text: "ออกจากระบบ",
          // path: "/contact",
          name: "logout",
          icon: "mdi-logout-variant",
        },
      ],
      items: [
        {
          src: require("@/assets/banners/Asset113.png"),
        },
        {
          src: require("@/assets/banners/Asset115.png"),
        },
        {
          src: require("@/assets/banners/Asset116.png"),
        },
        {
          src: require("@/assets/banners/banner_aug_18.png"),
        },
      ],
      agentData: null,
    };
  },
  watch: {
    group() {
      this.drawer = false;
    },
    "$store.state.Carts": {
      handler(array) {},
      deep: true,
    },
  },
  async created() {
    window.open("/lotterythai", "_self");
    if (Object.prototype.hasOwnProperty.call(localStorage, "DDLotUser")) {
      this.Navigator = this.NavigatorLogin;
      this.isLogedin = true;
      if (localStorage.getItem("DDLotUser")) {
        var user = JSON.parse(decode(localStorage.getItem("DDLotUser")));
        this.menuRight = user.user.firstName;
        console.log("userrr", user.user.firstName);
        this.getCart();
      }
    }
    await this.getAgents();
  },
  methods: {
    gotolink(val) {
      window.open(val, "_blank");
    },
    gotoDotNet() {
      this.$router.push("/");
    },
    gotohowtobuy() {
      // window.open("www.ddlottery.com/howtobuy");
      window.open("www.ddlottery.com/howtobuy", "_blank");
    },
    gotomember() {
      // console.log("error");
      this.$router.push("/member");
    },
    gotoprize() {
      window.open("www.ddlottery.com/prize", "_blank");
    },
    gotopurchaseconditions() {
      window.open("www.ddlottery.com/purchaseconditions", "_blank");
    },
    gotoabout() {
      window.open("www.ddlottery.com/aboutus", "_blank");
    },
    gotodealer() {
      window.open("www.ddlottery.com/dearler", "_blank");
    },
    gotocontact() {
      window.open("www.ddlottery.com/contact", "_blank");
    },
    // bluedragon() {
    //   window.open("https://bit.ly/2NV4evM", "_blank");
    // },
    clickPoweball() {
      this.$router.push({ path: "/powerBall" }).catch(() => {});
    },
    clickLotto() {
      window.open("https://bit.ly/2NV4evM", "_blank");
    },
    async clickLao() {
      await this.$swal.fire({
        icon: "warning",
        text: "พบกับลอตเตอรี่จากประเทศลาว เร็วๆนี้",
        showConfirmButton: true,
        // timer: 1500,
      });
    },
    convertDate(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },
    myTimer() {
      if (this.sec === 0) {
        this.min--;
        this.sec = 59;
      } else {
        this.sec--;
      }

      this.time = this.min + ":" + this.sec;
    },
    logout() {
      this.isLogedin = false;
      console.log("ออกแล้วโว้ย");
      // localStorage.removeItem
      localStorage.removeItem("DDLotUser");
      window.location.reload();
      // this.created()
    },
    async getAgents() {
      let agentCode = this.$route.query.agentCode;
      console.log("agentCode", agentCode);
      localStorage.setItem("agentCode", agentCode);
      this.agentCode = agentCode;
      if (agentCode) {
        const agentResponse = await this.axios.get(
          `${process.env.VUE_APP_API}/agents/agentCount/` + agentCode
        );
        console.log(agentResponse.data);
        if (agentResponse.data.response_status == "SUCCESS") {
          this.agentData = agentResponse.data.data;
        }
        console.log(this.agentData);
        const response = await this.axios.get(
          `${process.env.VUE_APP_API}/agents/` + this.agentCode
        );
        this.agentData = response.data.data;
        console.log("response", response.data.data);
      }
    },
    async getCart() {
      this.carts = [];
      var user = JSON.parse(decode(localStorage.getItem("DDLotUser")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      // console.log("user", user.token);
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/cart/findAllByUser/` + user.user.id,
        auth
      );

      this.$store.dispatch("ActionsSetCars", response.data.data);
      console.log("response", response.data.data);
      this.carts = response.data.data;

      this.content = this.carts.length;
      console.log("carts", this.carts);
    },
    async ChangePage(val) {
      // console.log("เข้า ChangePage", val);
      // // console.log(val, this.$router.currentRoute)
      if (val.name === "logout") {
        console.log("ออกแล้วโว้ย");
        // localStorage.removeItem
        localStorage.removeItem("DDLotUser");
        this.$router.push({ path: "/" }).catch(() => {});
        window.location.reload();
      } else if (val.name !== this.$router.currentRoute.name) {
        this.$router.push({ path: val.path }).catch(() => {});
      }
    },
    dialogPrivacy() {
      this.dialog = true;
    },
    printpo() {
      window.open("https://lin.ee/Ayc1oQR");
    },
    async ChangePageBtm(val) {
      // console.log("เข้า ChangePage", val);
      // // console.log(val, this.$router.currentRoute)

      this.$router.push({ path: val }).catch(() => {});
    },
  },
};
</script>
<style scoped>
.body-card {
  background-image: white;
  border: 1px;
  border-color: black;
  margin-bottom: 0;
}

.btn-to-top {
  width: 60px;
  height: 60px;
  padding: 10px 16px;
  border-radius: 50%;
  font-size: 22px;
  line-height: 22px;
  background: #235081;
}

.div_overflow {
  overflow: auto;
  width: 100%;
  height: 94vh;
}
.v-application ul,
.v-application ol {
  padding: 0px 0px !important;
}
.btm-btn {
  /* background-color: transparent !important; */
  padding: 10;
  border: 1px solid #235081;
}
.btm-btn::before {
  background-color: transparent !important;
}
.pb-10 {
  padding-bottom: 100px;
}
</style>
